import React from 'react'
import './src/styles/app.scss'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { rp } from './src/appinsightsConfig'

export const wrapRootElement = ({ element }) => {
    return (
        <AppInsightsContext.Provider value={rp}>
            {element}
        </AppInsightsContext.Provider>
    )
}