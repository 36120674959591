import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const rp = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.APPLICATIONINSIGHTS_CONNECTION_STRING,
        distributedTracingMode: 1,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableAjaxErrorStatusText: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [rp],
    }
})
appInsights.loadAppInsights()
appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'SPA'
})

export {rp, appInsights}
